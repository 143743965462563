import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calculator"
export default class extends Controller {
  static targets = ["main", "form"];

  connect() {
    console.log("hello from calculator");
    console.log(this.mainTarget);
    console.log(this.formTarget);
  }

  formRender(e) {
    console.log(e.target.value);
    const selection = e.target.value;

    const url = `build_cleaning_form?selection=${selection}`;
    const csrfToken = document.querySelector("meta[name='csrf-token']").content;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': csrfToken
      }
    })
    .then(res => res.json())
    .then(data => {
      this.mainTarget.innerHTML = data.html
    })
  }
}
