import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["form", "applicationForm"];

  connect() {
  }

  submit(e) {
    e.preventDefault();

    console.log('submitting form...')
    const url = this.applicationFormTarget.action
    fetch(url, {
      method: "POST",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.applicationFormTarget)
    })
      .then(response => response.text())
      .then((data) => {
        this.applicationFormTarget.outerHTML = data
      })
  }
}
