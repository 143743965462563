import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="render"
export default class extends Controller {
  static targets = [ "businessContent",
  "businessModalTitle",
  "businessCleaningType",
  "propertyCleaningType",
  "propertyCleaningSpecialty"
];
  static values = { type: String };

  connect() {
    console.log("hello from render");
    const gymCleaningRadioButton = this.element.querySelector('input[value="Gym Cleaning"]');
    gymCleaningRadioButton.click();
  }

  renderBusinessPartial(e) {
    let query = e.target.value;
    this.businessModalTitleTarget.innerText = `Business Owner/Investors - ${query}`;
    this.businessCleaningTypeTarget.value = query;
    query = query.replace(/&/g, 'n');
    const url = `render_partial?query=${query}`;
    const csrfToken = document.querySelector("meta[name='csrf-token']").content;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        'X-CSRF-Token': csrfToken
      }
    })
    .then(res => res.json())
    .then(data => {
      this.businessContentTarget.innerHTML = data.html;
    })
  }

  defaultType(e) {
    this.propertyCleaningSpecialtyTarget.checked = true;
  }
}
