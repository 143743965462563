import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="scroll"
export default class extends Controller {
  static targets = ["navbar", "about", "content", "navlink", "navClose", "button", "image", "csnavbar"];

  connect() {
  }

  enable() {
    this.contentTarget.classList.remove("d-none")
    this.aboutTarget.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" });
    this.navbarTarget.classList.remove("d-none");
    this.buttonTarget.classList.add("d-none");
    this.imageTarget.classList.add("blend-bg")
  }

  anchorTo(e) {
    e.preventDefault();

    // Close navbar
    this.navCloseTarget.click();

    // After 400ms, scroll to element
    setTimeout(() => {
      let scrollContent = document.getElementById(e.target.dataset.scrollValue);
      scrollContent.scrollIntoView({ block: "center", inline: "center" });
    }, 400);

    // Reassigning active class to correct section
    this.navlinkTargets.forEach((link) => {
      link.classList.remove("active");
    })
    e.target.classList.add("active");
  }

  fade() {
    const threshold = 400;
    const currentScroll = window.scrollY;

    if (currentScroll > threshold) {
      this.csnavbarTarget.classList.add("visible");
    } else {
      this.csnavbarTarget.classList.remove("visible");
    }
  }
}
